import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { CourseService } from 'src/app/service/course.service';
@Component({
  selector: 'app-peview-layout',
  templateUrl: './preview-layout.component.html',
  styleUrls: ['./preview-layout.component.scss'],
})
export class PreviewLayoutComponent implements OnInit {
  courseDetail: any;
  courseId: any;
  postData: any;
  isLoading: boolean = true;
  courseimg: any;
  sitePath: any;
  getCourseDocuments: any;
  courseDescriptionWords: any;
  readMoreStatus: any;
  launchURL: any;
  quizIdSubject: any;
  quizSubjectData: any;
  charLimit = 270;
  loadingStates: boolean[] = [];
  showTitle = false;
  noRecord: boolean;
  languageID: any;

  constructor(private renderer: Renderer2,
    public commonService: CommonService,
    private courseService: CourseService,
    private route: ActivatedRoute
  ) {
    // this.courseId = this.route.snapshot.paramMap.get('courseId');
    // console.log(this.courseId);
    this.route.firstChild?.paramMap.subscribe(params => {
      this.courseId = atob(params.get('courseId'));
      this.languageID = atob(params.get('langId'));
      console.log(this.courseId);
    });

    if (this.languageID == 1) {
      this.updateLocale('ger');
    } else {
      this.updateLocale('en');
    }
  }

  ngOnInit() {
    this.getCourseDetail()
  }

  collapseSideBarSection() {
    const pageelement = document.getElementById('pageContainer');
    if (pageelement.classList.contains('collapse-panel')) {
      this.renderer.removeClass(pageelement, 'collapse-panel');
      localStorage.setItem('courseSideBarcollapse', '1');
    } else {
      this.renderer.addClass(pageelement, 'collapse-panel');
      localStorage.setItem('courseSideBarcollapse', '0');
    }
  }

  getCourseDetail() {
    const postData = {
      courseId: this.courseId,
      method: 'courseDetailsBySlugs',
      lang_id: btoa(this.languageID)
    };

    this.courseService.getCourseDetailPreview(postData).subscribe(res => {
      if (res['status']) {

        this.noRecord = false;
        this.courseDetail = res['data'];
        // this.setFavicon(this.courseDetail.name);
        if (this.courseDetail != undefined) {
          // this.resetPanelCollapse();
          // read more
          this.courseDescriptionWords = this.courseDetail?.description?.split('').length;
          if (this.courseDescriptionWords > 270) {
            this.readMoreStatus = true;
          }
          this.getCourseDocuments = res['data']['documents'];
          this.courseimg = this.courseDetail.course_img != 'undefined' ? this.courseDetail.course_img : null;
        }
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      } else {
        this.noRecord = true;
      }
    }, error => error);
  }


  
  toggleMenuSection(element: HTMLElement, event: any, module_id?: any, index?: any): void {
    // Check if the menu is already open
    const isOpen = element.classList.contains('open');

    if (isOpen) {
      // Close the menu
      this.renderer.removeClass(element, 'open');
      this.renderer.setStyle(element, 'height', 0);
    } else {
      // Close all other menus first (if needed)
      const menus = document.querySelectorAll('.sidebar-child-menu');
      menus.forEach((menu) => {
        this.renderer.removeClass(menu, 'open');
        this.renderer.setStyle(menu, 'height', 0);
      });

      // Open the clicked menu
      // this.renderer.addClass(element, 'open');
      // this.renderer.setStyle(element, 'height', 'auto');

      this.renderer.addClass(element, 'open');
      setTimeout(() => {
        const childElmHeight = `${element.children[0].clientHeight}px`;
        this.renderer.setStyle(element, 'height', childElmHeight);
        const scrollElm = element.offsetTop;
      }, 100)
    }
  }

  private updateLocale(locale: string): void {
    const currentLocale = localStorage.getItem('locale');

    if (currentLocale !== locale) {
      localStorage.setItem('locale', locale);
      window.location.reload(); // Refresh the page only if locale changes
    }
  }

}
