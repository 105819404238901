import { Component, OnInit, Renderer2, DoCheck } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HeaderService } from './../../service/header.service';
import { SiteDomainService } from 'src/app/service/sitedomain.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from './../../service/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sitefooter',
  templateUrl: './sitefooter.component.html',
  styleUrls: ['./sitefooter.component.scss']
})
export class SitefooterComponent implements OnInit, DoCheck {
  themestyle: any;
  navStyle: any;
  domain: any;
  siteAddress: any;
  loginStatus: boolean = false;
  deviceInfo = null;
  privacyPolicyCookies: boolean = false;
  cookiesLiveStatus: boolean = false;
  footerHeading: string;
  currentYear: number;
  version: string;

  constructor(private router: Router, private route: ActivatedRoute,
    private headerService: HeaderService, private domainName: SiteDomainService, private renderer: Renderer2,
    private deviceService: DeviceDetectorService, private http: HttpClient, private commonService: CommonService) {
    this.domain = this.domainName.getDomainName();

    this.footerHeading = (this.domain.split('.')[1] == 'retteracademy' ? 'RETTER' : 'WEBER RESCUE');

    const getlocalStrg = JSON.parse(localStorage.getItem('theme_style'))
    if (getlocalStrg) {
      this.themestyle = getlocalStrg;
      this.styleThemeData(this.themestyle)
    }
    this.getSiteDetail(this.domain)

    this.version = environment.application_version;

  }

  ngOnInit() {
    if (localStorage.getItem('loggedUser')) {
      this.loginStatus = true;
    }

    this.checkCookiesAndStorage();
    this.checkUserCookies();
    
    this.currentYear = new Date().getFullYear();
  }

  ngDoCheck() {
    this.checkCookiesAndStorage()
  }

  routeToContact() {
    this.router.navigate(['contact-us']);
  }

  routeToImprint() {
    this.router.navigate(['imprint']);
  }

  routeToDataprotection() {
    this.router.navigate(['data-protection']);
  }

  getSiteDetail(segmentName) {
    this.headerService.getSiteDetail(segmentName).subscribe(res => {
      this.themestyle = res['data'];
      this.siteAddress = res['data'].address
      this.styleThemeData(this.themestyle)
      localStorage.setItem('theme_style', JSON.stringify(this.themestyle))
    }, error => error)
  }
  styleThemeData(theme) {
    this.navStyle = {
      background: theme.theme_style.footer_back_color,
      color: theme.theme_style.footer_text_color
    }
    this.siteAddress = theme.address;
  }

  /**
  * @description Cookies Agree
  * @date 2019-11-05
  * @memberof cookiesAgree
  */
  cookiesAgree() {
    this.setPrivacyPolicyParam(1);
    const now = new Date();
    const minutes = 30;
    now.setTime(now.getTime() + (minutes * 60 * 1000));
    document.cookie = "TELUSRPPSN = " + now + ";expires =" + now.toUTCString() + ";"
  }

  /**
  * @description Cookies Disagree
  * @date 2019-11-05
  * @memberof cookiesDisagree
  */
  cookiesDisagree() {
    this.setPrivacyPolicyParam(0);
    localStorage.setItem("user_policy", "false")
  }
  /**
  * @description Send user Browser data 
  * @date 2019-11-05
  * @param statusData
  * @memberof setPrivacyPolicyParam
  */
  setPrivacyPolicyParam(statusData) {
    this.deviceInfo = this.deviceService.getDeviceInfo()
    const setDeviceDetails = "browser:" + this.deviceInfo.browser + ",browser_version:" + this.deviceInfo.browser_version + ",device:" + this.deviceInfo.device + ",os:" + this.deviceInfo.os + ",os_version:" + this.deviceInfo.os_version + ",userAgent:" + this.deviceInfo.userAgent;
    const userPolicyData = {
      user_ip: '',
      user_device_address: '',
      user_browser_details: setDeviceDetails,
      id: this.commonService.getUserId(),
      user_id: this.commonService.getUserId(),
      method: "privacy_policy_tracking",
      status: statusData
    }

    this.commonService.setPrivacyPolicySession(userPolicyData).subscribe(
      (response) => {
        if (response['status']) {
          if(response['data']){
            if (response['data'][0]['status'] == 1) {
              this.privacyPolicyCookies = false;
              localStorage.setItem("user_policy", "true")
            }
          }
        

        }
      }
    )
  }
  /**
  * @description Check cookies 
  * @date 2019-11-05
  * @memberof checkCookiesAndStorage
  */
  checkCookiesAndStorage() {
    // if (document.cookie && localStorage.getItem('user_policy')) {
    //   this.privacyPolicyCookies = false
    // } else if (!document.cookie && localStorage.getItem('user_policy')) {
    //   this.privacyPolicyCookies = false
    // } else if (document.cookie && !localStorage.getItem('user_policy')) {
    //   this.privacyPolicyCookies = false
    // } else {
    //   this.privacyPolicyCookies = true
    // }
    if (localStorage.getItem('user_policy')) {
      this.privacyPolicyCookies = false
    } else {
      this.privacyPolicyCookies = true
    }
  }
  /**
  * @description Get privacy & policy data by user id
  * @date 2019-11-05
  * @memberof checkUserCookies
  */
  checkUserCookies() {
    const checkCookiesData = {
      id: this.commonService.getUserId(),
      user_id: this.commonService.getUserId(),
      method: "getPrivacyPolicyByUserId",
    }
    this.commonService.setPrivacyPolicySession(checkCookiesData).subscribe(
      (response) => {
        if (response != null) {
          // if (response['status']) {
          if (response['data']) {
            if (response['data'][0]['status'] == 1) {
              localStorage.setItem("user_policy", "true")
            } else if (response['data'][0]['status'] == 0) {
              localStorage.setItem("user_policy", "false")
            }
          }else{
            localStorage.removeItem('user_policy')
          }
          // } else {
          //   localStorage.removeItem('user_policy')
          // }
        }
      }
    )
  }



}
