import { AfterViewInit, Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import SignaturePad from "signature_pad";
import { CommonService } from 'src/app/service/common.service';
import { PresafetyService } from 'src/app/service/presafety.service';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-pre-safety-detail',
  templateUrl: './pre-safety-detail.component.html',
  styleUrl: './pre-safety-detail.component.scss'
})
export class PreSafetyDetailComponent implements OnInit, AfterViewInit {

  preSafe_id: any;
  lang: any;
  preSafeData: any;
  title: string;
  content: string;
  img_url: string;
  is_image: boolean = false;
  signStatus: any;
  hide: boolean = false;
  isSignPadEmpty: boolean = false;
  useremail: any;
  userid: any;
  presafetyCompleteStatus: any;
  sitemanagerStatus: any;
  show: boolean = false;
  questionData: any;
  top_questions: any;
  bottom_questions: any;
  selectedAnswers: any[] = [];
  quizForm: FormGroup;
  private _snackBar = inject(MatSnackBar);
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  unansweredMandatory: any[] = [];
  unasweredQue: string = '';

  constructor(
    private preSafeService: PresafetyService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private translate: TranslateService
  ) {
    route.params.subscribe(res => {
      this.preSafe_id = atob(res.id);
    });
  }

  @ViewChild('canvas', { static: false }) canvas: ElementRef;

  sign: SignaturePad;

  ngOnInit(): void {
    if (localStorage.getItem('locale') === 'ger') {
      this.lang = 1;
    } else {
      this.lang = 2;
    }
    this.viewPreSafe();

    setTimeout(() => {
      this.presafetyCompleteStatus = localStorage.getItem('complete');
      this.show = true;
    }, 1500);

    this.preSafeService.briefingComplete.subscribe(res => {
      this.signStatus = res;
    })


    this.preSafeService.userId.subscribe(res => {
      this.userid = res;
    })

    this.preSafeService.userEmail.subscribe((res: any) => {
      this.useremail = res;
      this.preSafeService.emailReturn.next(this.useremail);
    })
  }

  openSnackBar() {
    // Use ngx-translate to localize the message dynamically
    this.translate.get('mandatory_question').subscribe((translatedMessage: string) => {
      this._snackBar.open(
        translatedMessage,
        '',
        {
          duration: 2000, // Duration in milliseconds
          horizontalPosition: this.horizontalPosition || 'right', // Default to 'right' if not set
          verticalPosition: this.verticalPosition || 'top', // Default to 'top' if not set
        }
      );
    });
  }


  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.canvas && this.canvas.nativeElement) {
        this.sign = new SignaturePad(this.canvas.nativeElement);
      }
    }, 2000);
  }

  viewPreSafe() {
    let containerData = JSON.parse(localStorage.getItem('theme_style'));
    console.log("theme style container...", containerData.id);

    const postData = {
      method: "viewBriefingDetail",
      record_id: this.preSafe_id,
      lang_id: this.lang,
      id: this.commonService.getUserId(),
      user_id: localStorage.getItem("uid"),
      container_id: containerData.id
    };

    this.preSafeService.viewBriefingDetail(postData).subscribe((res: any) => {
      if (res['jwt_status']) {
        if (res['status']) {
          this.preSafeData = res['data'];
          this.title = this.preSafeData.title;
          this.content = this.preSafeData.briefings_content;
          this.img_url = this.preSafeData.img_url;
          this.is_image = true;
          this.sitemanagerStatus = this.preSafeData.site_manage_status;
          if (this.sitemanagerStatus == 1) {
            localStorage.setItem("complete", "complete");
            this.presafetyCompleteStatus == 'complete'
          } else {
            localStorage.setItem("complete", "pending");
            this.presafetyCompleteStatus == 'pending'
          }


          if (this.signStatus == 'complete') {
            this.hide = true;
          } else {
            this.hide = false;
          }


          this.questionData = this.preSafeData.getBriefingQuestionGroup;
          let top_questions = this.questionData?.top;
          let topQues = [];
          top_questions.forEach(element => {
            element.questions.forEach(elem => {
              topQues.push({
                ...elem,
                category_id: element.id
              });
            })
          });
          this.top_questions = topQues;


          let bottom_questions = this.questionData?.bottom;
          let bottomQues = [];
          bottom_questions.forEach(element => {
            element.questions.forEach(elem => {
              bottomQues.push({
                ...elem,
                category_id: element.id
              });
            })
          });
          this.bottom_questions = bottomQues;
        }
        else {
          this.router.navigate(['pre-safety-list'])
        }
      } else {
        this.commonService.openSessionExpireDialog();
      }

      console.log("check the condition of image and complete status => ", this.img_url == '' && this.presafetyCompleteStatus == 'complete');

    });
  }

  get sanitizedContent(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.content);
  }

  submitSignIfNotEmpty() {
    if (!this.submitPreSafetyQuestions()) {
      this.openSnackBar()
      return; // Exit the function if mandatory questions are not answered
    }

    if (!this.sign) {
      console.error('Sign pad is not initialized.');
      return;
    }

    if (this.sign.isEmpty()) {
      this.isSignPadEmpty = true;
      setTimeout(() => {
        this.isSignPadEmpty = false;
      }, 2000);
    } else {
      this.submitSign();
    }
  }

  submitSign() {
       const postData = {
      method: "saveBriefingDetail",
      record_id: this.preSafe_id,
      lang_id: this.lang,
      id: this.commonService.getUserId(),
      user_id: localStorage.getItem("uid"),
      signature_image: this.sign.toDataURL(),
      submit_answers: this.selectedAnswers,
      presafety_briefing_id: this.preSafe_id
    }

    console.log(postData);

    this.preSafeService.saveBriefing(postData).subscribe((res: any) => {
      this.router.navigate(['/pre-safety-list'])
    })
    this.clearSign()
  }

  clearSign() {
    this.sign.clear()
  }


  routeToPreSafetyList() {
    this.router.navigate(['/pre-safety-list'])
  }

  // Store selected answers
  onOptionChange(questionId: number, type: string, optionId: number, event: any, catId: number, mandatory: boolean): void {
    const existingAnswerIndex = this.selectedAnswers.findIndex(ans => ans.qu_id === questionId);
    let answerObj = existingAnswerIndex > -1 ? this.selectedAnswers[existingAnswerIndex] : {
      qu_id: questionId,
      ans_id: null,
      ans_txt: '',
      ans_multi: [],
      category_id: catId
    };

    if (type === '3' || type === '4') {
      answerObj.ans_id = optionId;
      answerObj.ans_txt = this.getOptionText(questionId, optionId);
      answerObj.ans_multi = null;
    }
    else if (type === '2') {
      if (event.target.checked) {
        answerObj.ans_multi.push(optionId);
      } else {
        answerObj.ans_multi = answerObj.ans_multi.filter(id => id !== optionId);
      }
      answerObj.ans_id = null;
    }
    else if (type === '1') {
      answerObj.ans_txt = event.target.value.trim();
      answerObj.ans_id = null;
      answerObj.ans_multi = null;
    }

    // Update or add the answer object to the `selectedAnswers` array
    if (existingAnswerIndex > -1) {
      // Update the existing answer object
      this.selectedAnswers[existingAnswerIndex] = answerObj;
    } else {
      // Add a new answer object
      this.selectedAnswers.push(answerObj);
    }
  }

  // Get option text by question ID and option ID
  getOptionText(questionId: number, optionId: number): string {
    // Check if `top_questions` and `questions` are defined
    const question = this.top_questions?.questions?.find(q => q.id === questionId);

    if (question && question.catQuestionOptions) {
      const option = question.catQuestionOptions.find(opt => opt.id === optionId);
      return option ? option.answer : '';  // Return option text if found
    }
    return '';  // Return empty string if question or options are not found
  }

  // Submit quiz
  submitPreSafetyQuestions(): boolean {
    let allquestions = [];
    let topQues = this.top_questions;
    let bottomQues = this.bottom_questions;

    // Combine top and bottom questions into one array
    if (topQues?.length > 0) {
      topQues.forEach(element => {
        allquestions.push(element);
      });
    }
    if (bottomQues?.length > 0) {
      bottomQues.forEach(element => {
        allquestions.push(element);
      });
    }

    console.log("allquestions", allquestions);

    let mandatoryQuestions = allquestions.filter(question => question.is_mandatory === '1');

    this.unansweredMandatory = mandatoryQuestions.filter(mandatoryQuestion => {
      let answered = this.selectedAnswers.find(answer => answer.qu_id === mandatoryQuestion.id);
      return !answered || (!answered.ans_id && !answered.ans_txt && (!answered.ans_multi || answered.ans_multi.length === 0));
    });


    if (this.unansweredMandatory.length > 0) {
      return false;
    }

    // Handle non-solved questions when all mandatory questions are answered
    allquestions.forEach(question => {
      let answered = this.selectedAnswers.find(answer => answer.qu_id === question.id);
      if (!answered) {
        this.selectedAnswers.push({
          qu_id: question.id,
          ans_id: null,
          ans_txt: '',
          ans_multi: null,
          category_id: question.category_id || null
        });
      }
    });
    return true;
  }
}
